.login__box{
    padding-top: 50px;
}

.login__box__card{
    display: flex;
    justify-content: center; 
}

#login__card {
    width: 80%;
    align-items: center;
}

.form-label {
    justify-content: center;
}

.form__text__mail {
    align-items: center;
}


.form-group {
    flex-direction: column;
    align-items: center;
}

.login__card__title {
    margin: 20px;
}

#email,
#password {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}

.login__forgot__pswd {
    padding-bottom: 10px;
}