* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: #eff3f7;
  height: 100%;
}

.project__section {
  height: 50%;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* align-items: center; */
}

.project__container {
  width: 70%;
  background-color: white;
  margin-bottom: 10px;
  font-weight: 300;
  padding: 20px;
  border-radius: 5px;
  color: #adaeb9;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  text-align: center;
}

.project__container span:first-child {
  display: block;
  font-size: 20px;
  text-align: center;
}

.project__container span:last-child {
  display: block;
  margin-top: 10px;
  text-align: center;
}

.plain {
  text-decoration: none;
}

.project__title {
  font-weight: 300;
  font-size: 20px;
}

.project__desc {
  color: black;
}
