.nav {
  /* position: fixed; */
  display: flex;
  background-color: white;
  padding: 10px 20px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  border-radius: 5px;
}

.nav__link {
  display: flex;
}

.nav :first-child {
  padding-right: 10px;
}

.nav a {
  text-decoration: none;
  color: #181818;
  text-transform: uppercase;
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}

.nav a:not(:last-child) {
  margin-bottom: 20px;
}
